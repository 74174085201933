import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { DateTimes } from '@/constants/DateTimes';
import useMultiplePlatformArtistMetrics from '@/hooks/metrics/useMultiplePlatformArtistMetrics';
import { MetricsPlatformArtistModel } from '@/models/Metrics';

import Loading from '../utility/Loading';
import ButtonComponent from '../utility/microcomponents/Button';
import TileCardChart from './TileCardChart';

const SocialTilesCard = () => {
  const navigate = useNavigate();
  const [noData, setNoData] = useState<boolean>(false);

  const { t } = useTranslation();

  const { multiPlatformArtistMetrics, multiPlatformArtistMetricsIsLoading, multiPlatformArtistMetricsError } =
    useMultiplePlatformArtistMetrics({
      platforms: ['instagram', 'youtube_channel', 'deezer', 'tiktok', 'spotify', 'facebook', 'twitter', 'soundcloud'],
      fromDate: DateTimes.oneWeekAgoDate,
      toDate: DateTimes.yesterdayDate,
    });

  const [
    instaData,
    youtubeData,
    deezerData,
    tiktokData,
    spotifyData,
    facebookData,
    twitterData,
    soundcloudData,
  ]: MetricsPlatformArtistModel[] = useMemo(() => {
    const defaultMetrics: MetricsPlatformArtistModel = {
      followers: [],
      likes: [],
      views: [],
      subscribers: [],
      listeners: [],
      popularity: [],
      conversion: [],
      fans: [],
      talks: [],
      retweets: [],
    };
    return multiPlatformArtistMetrics ?? Array(8).fill(defaultMetrics);
  }, [multiPlatformArtistMetrics]);

  const createTile = (
    platform: string,
    name: string,
    data: MetricsPlatformArtistModel,
    type: keyof MetricsPlatformArtistModel,
    linkInfo: string
  ) => ({
    platform,
    name,
    total: data?.[type]?.[data[type].length - 1]?.value || 0,
    type,
    dataInput: data?.[type],
    linkInfo,
  });

  const graphTiles = useMemo(
    () => [
      createTile('spotify', 'Spotify', spotifyData, 'listeners', 'music'),
      createTile('spotify', 'Spotify', spotifyData, 'followers', 'music'),
      createTile('spotify', 'Spotify', spotifyData, 'popularity', 'music'),
      createTile('spotify', 'Spotify', spotifyData, 'conversion', 'music'),
      createTile('instagram', 'Instagram', instaData, 'followers', 'socials'),
      createTile('youtube', 'YouTube', youtubeData, 'subscribers', 'music'),
      createTile('youtube', 'YouTube', youtubeData, 'views', 'music'),
      createTile('tiktok', 'TikTok', tiktokData, 'followers', 'socials'),
      createTile('tiktok', 'TikTok', tiktokData, 'likes', 'socials'),
      createTile('facebook', 'Facebook', facebookData, 'likes', 'socials'),
      createTile('facebook', 'Facebook', facebookData, 'talks', 'socials'),
      createTile('deezer', 'Deezer', deezerData, 'fans', 'music'),
      createTile('twitter', 'Twitter', twitterData, 'followers', 'socials'),
      createTile('twitter', 'Twitter', twitterData, 'retweets', 'socials'),
      createTile('soundcloud', 'Soundcloud', soundcloudData, 'followers', 'music'),
    ],
    [deezerData, facebookData, instaData, soundcloudData, spotifyData, tiktokData, twitterData, youtubeData]
  );

  useEffect(() => {
    if (
      deezerData.fans.length === 0 &&
      facebookData.likes.length === 0 &&
      facebookData.talks.length === 0 &&
      instaData.followers.length === 0 &&
      soundcloudData.followers.length === 0 &&
      spotifyData.listeners.length === 0 &&
      spotifyData.followers.length === 0 &&
      spotifyData.popularity.length === 0 &&
      spotifyData.conversion.length === 0 &&
      tiktokData.followers.length === 0 &&
      tiktokData.likes.length === 0 &&
      twitterData.followers.length === 0 &&
      twitterData.retweets.length === 0 &&
      youtubeData.subscribers.length === 0 &&
      youtubeData.views.length === 0 &&
      !multiPlatformArtistMetricsIsLoading
    ) {
      setNoData(true);
    } else {
      setNoData(false);
    }
  }, [
    deezerData,
    facebookData,
    instaData,
    multiPlatformArtistMetricsIsLoading,
    soundcloudData,
    spotifyData,
    tiktokData,
    twitterData,
    youtubeData,
  ]);

  return (
    <>
      <div className="card min-h346 w100p h100p flex-grow">
        <div className="d-flex mb20 gap8">
          <p className="text-faded mt8">{t('COMMON.LAST-7-DAYS')}</p>
        </div>
        {multiPlatformArtistMetricsError && (
          <div className="text-center mt100">
            <h4 data-testid="no-data-available">{t('COMMON.WE-COULD-NOT-GET-YOUR-INSIGHTS')}</h4>
          </div>
        )}
        {noData && !multiPlatformArtistMetricsError && !multiPlatformArtistMetricsIsLoading && (
          <div className="text-center mt100">
            <h4 data-testid="no-data-available">{t('COMMON.WE-COULD-NOT-GET-YOUR-INSIGHTS')}</h4>
          </div>
        )}
        <div className="d-flex graph-card-container gap20">
          {multiPlatformArtistMetricsIsLoading && (
            <div className="centered-loading mt100 mb48">
              <Loading size="small" />
            </div>
          )}

          {!multiPlatformArtistMetricsIsLoading &&
            graphTiles.map((item, index: number) => {
              if (!(item.dataInput && item.dataInput?.length > 0)) return null;
              return (
                <ButtonComponent
                  isCustom
                  className="graph-item cursor-pointer text-left p0"
                  key={index}
                  style={
                    {
                      '--animation-number': `${index}`,
                    } as React.CSSProperties
                  }
                  onClick={() => {
                    navigate(`/insights?tab=${item.linkInfo}&platform=${item.platform}`);
                  }}
                >
                  <div className="card-inner min-w200">
                    <TileCardChart
                      platform={item.platform}
                      total={item.total}
                      name={item.name}
                      type={item.type}
                      dataInput={item.dataInput}
                    />
                  </div>
                </ButtonComponent>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default SocialTilesCard;
