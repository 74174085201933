import React from 'react';

import { Icon } from '@mui/material';

import Card from '../utility/microcomponents/Card';

const GenericBannerCard = ({
  title,
  description,
  onClose,
}: {
  title?: string;
  description?: string;
  onClose: () => void;
}) => {
  return (
    <>
      <Card className="text-left mb20">
        <div className="d-flex gap8 jc-space-between">
          <h4 className="">{title}</h4>
          <Icon className="material-symbols-outlined cursor-pointer" onClick={onClose}>
            close
          </Icon>
        </div>
        <p className="small text-faded">{description}</p>
      </Card>
    </>
  );
};

export default GenericBannerCard;
