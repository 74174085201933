import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Icon } from '@mui/material';
import { JsonType } from 'posthog-js';

import { FeatureFlagNameFormatter } from '@/constants/FeatureFlagEvents';
import { HomeScreenBannerModel } from '@/models/Banners';
import useFeatureFlags from '@/services/FeatureFlag';
import UserTracking from '@/services/UserTracking';

const HomeScreenBanner = () => {
  const [bannerInfo, setBannerInfo] = useState<HomeScreenBannerModel>();
  const navigate = useNavigate();
  const { getFeatureFlagPayload } = useFeatureFlags();

  useEffect(() => {
    const data: HomeScreenBannerModel | JsonType = getFeatureFlagPayload(FeatureFlagNameFormatter('homeScreenBanner'));

    if (data) {
      setBannerInfo(data as HomeScreenBannerModel);
    }
  }, [getFeatureFlagPayload]);

  const setBackground = useMemo(() => {
    if (bannerInfo?.image && bannerInfo?.image !== '') {
      return {
        backgroundImage: `url(${bannerInfo?.image})`,
        border:
          bannerInfo?.customProperties.borderColor &&
          bannerInfo?.customProperties.borderColor !== '' &&
          `${bannerInfo?.customProperties.borderColor} 1px solid`,
        backgroundSize: 'cover',
        backgroundPosition: '0% 15%',
      } as CSSProperties;
    } else {
      return {
        background: bannerInfo?.customProperties.backgroundColor,
        border:
          bannerInfo?.customProperties.borderColor &&
          bannerInfo?.customProperties.borderColor !== '' &&
          `${bannerInfo?.customProperties.borderColor} 1px solid`,
        backgroundSize: 'cover',
        backgroundPosition: '0% 15%',
      } as CSSProperties;
    }
  }, [bannerInfo]);

  const bannerClicked = () => {
    if (bannerInfo?.ctaLink) {
      if (bannerInfo?.internalLink) {
        navigate(bannerInfo?.ctaLink);
      } else {
        window.open(bannerInfo?.ctaLink, '_blank');
      }
    }
    UserTracking.customGoogleEvent('User', 'Homepage Banner Clicked');
  };

  return (
    <div
      className={`card w100p mb20 d-flex jc-space-between flex-wrap ${bannerInfo?.ctaLink ? 'cursor-pointer' : ''}`}
      onClick={() => {
        if (!bannerInfo?.ctaLink) return;
        bannerClicked();
      }}
      style={setBackground as CSSProperties}
    >
      <div>
        <h3
          className={`fc-custom
        ${bannerInfo?.customProperties.titleSize && bannerInfo?.customProperties.titleSize !== '' ? 'fs-custom' : ''}`}
          style={
            {
              '--customFontSize': bannerInfo?.customProperties.titleSize,
              '--customFontColor': bannerInfo?.customProperties.titleColor,
            } as CSSProperties
          }
        >
          {bannerInfo?.title}
        </h3>
        <p
          className={`fc-custom mt8
          ${
            bannerInfo?.customProperties.descriptionSize && bannerInfo?.customProperties.descriptionSize !== ''
              ? 'fs-custom'
              : ''
          }`}
          style={
            {
              '--customFontSize': bannerInfo?.customProperties.descriptionSize,
              '--customFontColor': bannerInfo?.customProperties.descriptionColor,
            } as CSSProperties
          }
        >
          {bannerInfo?.description1}
        </p>
        <p
          className={`fc-custom
          ${
            bannerInfo?.customProperties.descriptionSize2 && bannerInfo?.customProperties.descriptionSize2 !== ''
              ? 'fs-custom'
              : ''
          }`}
          style={
            {
              '--customFontSize': bannerInfo?.customProperties.descriptionSize2,
              '--customFontColor': bannerInfo?.customProperties.descriptionColor2,
            } as CSSProperties
          }
        >
          {bannerInfo?.description2}
        </p>
      </div>
      {bannerInfo?.ctaText && bannerInfo?.ctaText !== '' && (
        <Button className="no-bg p0 m0 mt10">
          {bannerInfo?.ctaText}
          <Icon>chevron_right</Icon>
        </Button>
      )}
    </div>
  );
};

export default HomeScreenBanner;
