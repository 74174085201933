import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Icon } from '@mui/material';

import useActiveCampaigns from '@/hooks/campaigns/useActiveCampaigns';

import Loading from '../utility/Loading';
import Card from '../utility/microcomponents/Card';
import ListItem from '../utility/microcomponents/ListItem';
import ActiveCampaignsStatus from '../utility/statuses/ActiveCampaignStatus';

const ActiveCampaignsCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { activeCampaigns, activeCampaignsIsLoading } = useActiveCampaigns();

  const playlistPitchText = useMemo(() => {
    if (activeCampaigns?.playlistPitches?.recentlyReviewed && activeCampaigns?.playlistPitches?.recentlyReviewed > 0) {
      return `You have ${activeCampaigns?.playlistPitches?.recentlyReviewed} recent playlist pitch review${activeCampaigns?.playlistPitches?.recentlyReviewed === 1 ? '' : 's'}`;
    } else if (activeCampaigns?.playlistPitches?.pending && activeCampaigns?.playlistPitches?.pending > 0) {
      return `You have ${activeCampaigns?.playlistPitches?.pending} pending playlist pitch${activeCampaigns?.playlistPitches?.pending === 1 ? '' : 'es'}`;
    } else {
      return 'You have no pending playlist pitches';
    }
  }, [activeCampaigns]);

  return (
    <>
      <Card title="PAGE-TITLES.CAMPAIGNS" className="min-h310 w100p h100p">
        {activeCampaignsIsLoading && (
          <div className="centered-loading mt100 mb48">
            <Loading size="small" />
          </div>
        )}
        {!activeCampaignsIsLoading && activeCampaigns && (
          <div>
            {activeCampaigns?.playlistPitches?.recentlyReviewed > 0 || activeCampaigns?.playlistPitches?.pending > 0 ? (
              <ListItem
                title="Check your playlist pitches for any updates"
                className="mb8"
                onClick={() => {
                  navigate('/campaigns', { state: 1 });
                }}
                icon="/images/icons/playlists-icon.svg"
                subtitle={
                  <div className="mt10">
                    <ActiveCampaignsStatus
                      text={playlistPitchText}
                      color={activeCampaigns?.playlistPitches?.recentlyReviewed > 0 ? 'blue' : 'pending'}
                    />
                  </div>
                }
              />
            ) : (
              <ListItem
                title="Pitch your track to our Spotify playlist curators"
                className="mb8"
                icon="/images/icons/playlists-icon.svg"
                button={
                  <Button
                    className="btn-white ml-auto mb0 mr0"
                    onClick={() => {
                      navigate('/promote?promoteState=playlisting');
                    }}
                  >
                    <Icon>add</Icon>
                    <span className="pl8 mt-auto mb-auto small">{t('CAMPAIGNS-PAGE.NEW-CAMPAIGN')}</span>
                  </Button>
                }
              />
            )}
            {activeCampaigns?.socialAds?.active && activeCampaigns?.socialAds?.active > 0 ? (
              <ListItem
                title="Check your ad campaigns for any updates"
                icon="/images/icons/campaigns-icon.svg"
                onClick={() => {
                  navigate('/campaigns', { state: 0 });
                }}
                subtitle={
                  <div className="mt10">
                    <ActiveCampaignsStatus
                      text={`You have ${activeCampaigns?.socialAds?.active} active ad campaign${activeCampaigns?.socialAds?.active === 1 ? '' : 's'}`}
                    />
                  </div>
                }
              />
            ) : (
              <ListItem
                title="Start an ad campaign to reach your marketing goals"
                icon="/images/icons/campaigns-icon.svg"
                button={
                  <Button
                    className="btn-white ml-auto mb0 mr0"
                    onClick={() => {
                      navigate('/promote?promoteState=meta');
                    }}
                  >
                    <Icon>add</Icon>
                    <span className="pl8 mt-auto mb-auto small">{t('CAMPAIGNS-PAGE.NEW-CAMPAIGN')}</span>
                  </Button>
                }
              />
            )}
          </div>
        )}
      </Card>
    </>
  );
};

export default ActiveCampaignsCard;
