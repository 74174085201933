import React from 'react';

import ChartGradients from '@/constants/ChartGradients';
import numberFormatter from '@/formatters/ShortNumberConverter';
import { MetricsDataModel } from '@/models/Metrics';

import AreaChartTileComponent from '../charts/AreaChartTile';

const TileCardChart = ({
  platform,
  total,
  type,
  dataInput,
  name,
}: {
  platform: string;
  total: number;
  type: string;
  dataInput: MetricsDataModel[];
  name: string;
}) => {
  return (
    <>
      <img src={`/images/logos/${platform}-logo.svg`} alt={`${platform}`} className="" />
      <div>
        <ChartGradients />
        <AreaChartTileComponent data={dataInput} color={platform} name={type} />
        {dataInput && dataInput.length === 0 && <p className="small">No data from {name} yet</p>}
        {dataInput && dataInput.length > 0 && <h4>{numberFormatter(total)}</h4>}
        {dataInput && dataInput.length > 0 && <p className={`text-brand ${platform} small capitalize`}>{type}</p>}
      </div>
    </>
  );
};

export default TileCardChart;
